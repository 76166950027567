import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'mutate-copy-delete'

export default {
  label: LABEL,
  name: 'Mutate Copy and Delete',
  action: `
  When using the Workflow Engine, it's helpful to have standards and best practices. Though you can put data anywhere you 
  want on the payload, \`working\` is a best practice we settled on at Losant. Everything in \`working\` 
  represents data that we are actively manipulating within a workflow. Using \`working\` you can always assume that the 
  rest of the payload is untouched, and you may use those values in other nodes without worry.
  
  In this test, you will move and delete things on the payload. A JSON object containing random \`data\` and an empty \`working\` object 
  will be provided to your Webhook.  Return a JSON object with a single field, "result", 
  that contains a copy of the original payload that you received. Then make a second copy of 
  just \`data\` and add it to the empty \`working\` object in the result. For this second copy, 
  delete the field "deleteMe".
  `,
  recommended: `Resources: [Mutate Node](/workflows/logic/conditional/)`,
  examples: [
    {
      input: {
        data: {
          temperature: 97,
          machineFaultCode: 1284,
          deleteMe: 'delete me',
        },
        working: {},
      },
      output: {
        result: {
          data: {
            temperature: 97,
            machineFaultCode: 1284,
            deleteMe: 'delete me',
          },
          working: {
            data: {
              temperature: 97,
              machineFaultCode: 1284,
            },
          },
        },
      },
    },
  ],
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function mutateAdd(webhook, test) {
      const input = {
        data: {
          temperature: Chance.integer({ min: -20, max: 50 }),
          machineFaultCode: Chance.integer({ min: 1000, max: 3000 }),
          deleteMe: 'delete me',
        },
        working: {},
      }

      const data = { ...input.data }
      delete data.deleteMe

      const expectedValue = {
        data: input.data,
        working: {
          data,
        },
      }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false

      try {
        assert.deepEqual(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message: null,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(3, [userWebhook, this], mutateAdd)

    return this
  },
}
