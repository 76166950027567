import { useState, useEffect, useCallback } from 'react'

function getCookie(name) {
  // Wrap the require in check for window
  if (typeof window === `undefined`) {
    return null
  }
  const value = `; ${document.cookie}`

  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
  return null
}

function useFetch(url) {
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)
  const token = getCookie('losant.token')

  const fetchUrl = useCallback(async () => {
    try {
      const response = await fetch(`https://api.losant.com${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const json = await response.json()
      setData(json)
    } catch (error) {
      setError(true)
    }
  }, [token, url])

  useEffect(() => {
    if (token) {
      fetchUrl()
    } else {
      setError(true)
    }
  }, [url, fetchUrl, token])

  return [data, error]
}

export default useFetch
