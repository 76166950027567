import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'add-random-number-array'

export default {
  label: LABEL,
  name: 'Sum an Array of Random Numbers',
  action: `
  When dealing with an array of data, you may want to perform an aggregation 
  on the dataset before you report it as state to Losant. In this test, we are going to sum every element in the array.

  A JSON object containing an array of random numbers, "numbers", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is the sum of every number in that array.
  `,
  examples: [
    {
      input: {
        numbers: [8, 5, 12, 6],
      },
      output: {
        result: 31,
      },
    },
    {
      input: {
        numbers: [20, 21, 22, 23],
      },
      output: { result: 86 },
    },
  ],
  recommended: `Resources: [Mutate Node](/workflows/logic/mutate/), [Loop Node](/workflows/logic/loop/), [Math Node](/workflows/logic/math/)`,

  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function addRandomArray(webhook, test) {
      const count = Math.floor(Math.random() * 20) + 1
      const numbers = []
      let expectedValue = 0
      for (let i = 0; i < count; i += 1) {
        const number = Chance.integer({ min: 0, max: 100 })
        expectedValue += number
        numbers.push(number)
      }

      const input = { numbers }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], addRandomArray)

    return this
  },
}
