import Test from '../../Test'

// Tests :)
import add from './add'
import copyAndDelete from './copyAndDelete'
import moveAndAdd from './moveAndAdd'

const exercises = {}

exercises[add.label] = new Test(add)
exercises[copyAndDelete.label] = new Test(copyAndDelete)
exercises[moveAndAdd.label] = new Test(moveAndAdd)

export default {
  name: 'Mutate',
  isRunning: false,
  webhook: null,
  description: `The [Mutate Node](/workflows/logic/mutate/) is a hidden gem in the Workflow Engine. Most of the actions you build in a workflow will be manipulating the payload in some way, and the Mutate Node allows for direct manipulation of the payload. This series of test will take you through common Mutate Node actions. 
  
  The Starter Workflow for this test suite is available [here](https://files.onlosant.com/5c40ecbac853d20008cd96ba/starter/starter-workflow-mutate.flow).`,
  exercises,
}
