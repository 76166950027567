import React from 'react'
import { assert } from 'chai'

import { ExerciseGroup, ExercisesDispatch } from './index'
import Test from './Test'
import Runner from './Runner'

import '../../styles/lab.scss'

const HelloWorldExercise = {
  name: 'Hello World',
  label: '0.0',
  isRunning: false,
  webhook: null,
  description: `The questions in this test suite are designed to get you working comfortably with the Losant Workflow Lab.

  The [Starter Workflow](#starter-workflows) for this test suite is available [here](https://files.onlosant.com/5c40ecbac853d20008cd96ba/starter/hello-world.flow).
  `,
  exercises: {
    'hello-world': new Test({
      label: 'hello-world',
      name: 'Hello World',
      action: `In this test, a JSON object containing two strings, "string1" and "string2", will be provided to your Webhook.
      Your workflow should return a JSON object containing a single field, "result", that is the concatenation of "string1" and "string2".`,
      examples: [
        {
          input: { string1: 'Hello', string2: ' World' },
          output: {
            result: 'Hello World',
          },
        },
      ],
      async exercise(userWebhook) {
        // Tests need to be in a function to run multiple times
        async function helloWorld(webhook, test) {
          const string1 = 'Hello'
          const string2 = ' World'

          const expectedValue = string1 + string2
          const input = { string1, string2 }
          // Test.call will actually trigger the workflow
          const body = await Test.call(webhook, test.label, input)
          const expected = { result: expectedValue }
          const actual = body.data

          let passed = false
          const message = null

          try {
            assert.equal(
              body.data.result,
              expectedValue,
              `Expected result to equal ${expectedValue}`
            )
            passed = true
          } catch (error) {
            passed = false
          }

          test.addContext(
            {
              input,
              expected,
              actual,
              passed,
              message,
              body,
            },
            passed
          )

          return passed
        }

        await Test.performMultipleTimes(5, [userWebhook, this], helloWorld)

        return this
      },
    }),
    add: new Test({
      label: 'add',
      name: 'Add Two Numbers',
      action: `In this test, a JSON object containing two random numbers, "num1" and "num2", will be provided to your Webhook.
      Your workflow should return a JSON object containing a single field, "result", that is the sum of "num1" and "num2".`,
      examples: [
        {
          input: { num1: 3, num2: 5 },
          output: {
            result: 8,
          },
        },
        {
          input: {
            num1: 7,
            num2: 21,
          },
          output: { result: 28 },
        },
      ],

      async exercise(userWebhook) {
        // Tests need to be in a function to run multiple times
        async function addTwoNumbers(webhook, test) {
          const num1 = Math.floor(Math.random() * 100)
          const num2 = Math.floor(Math.random() * 100)

          const expectedValue = num1 + num2
          const input = { num1, num2 }
          // Test.call will actually trigger the workflow
          const body = await Test.call(webhook, test.label, input)
          const expected = { result: expectedValue }
          const actual = body.data

          let passed = false
          const message = null

          try {
            assert.equal(
              body.data.result,
              expectedValue,
              `Expected result to equal ${expectedValue}`
            )
            passed = true
          } catch (error) {
            passed = false
          }

          test.addContext(
            {
              input,
              expected,
              actual,
              passed,
              message,
              body,
            },
            passed
          )

          return passed
        }

        await Test.performMultipleTimes(5, [userWebhook, this], addTwoNumbers)

        return this
      },
    }),
  },
}

const HelloWorld = () => {
  const Exercises = {
    HelloWorldExercise,
    meta: {
      isRunning: false, // this is used in the front-end to know if something is running
      exerciseCount: Object.keys(HelloWorldExercise.exercises).length,
    },
  }
  const [
    exercises,
    setExercises,
    runExercise,
    runSuite,
    // eslint-disable-next-line
    runAll,
    counter,
    counterDispatch,
  ] = Runner(Exercises)

  return (
    <>
      <ExercisesDispatch.Provider
        value={{
          runExercise,
          runSuite,
          setExercises,
          counter,
          counterDispatch,
        }}
      >
        <ExerciseGroup
          exercises={exercises}
          key="HelloWorldExercise"
          groupName="HelloWorldExercise"
        />
      </ExercisesDispatch.Provider>
    </>
  )
}

export default HelloWorld
