import Test from '../../Test'

import stringConcat from './stringConcat'
import stringSeparation from './stringSeparation'
import stringSplit from './stringSplit'
import compareEmails from './compareEmails'

const exercises = {}

exercises[stringConcat.label] = new Test(stringConcat)
exercises[stringSeparation.label] = new Test(stringSeparation)
exercises[stringSplit.label] = new Test(stringSplit)
exercises[compareEmails.label] = new Test(compareEmails)

export default {
  name: 'String',
  isRunning: false,
  webhook: null,
  description: `Each question in this test suite involves solving [string](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String) questions using a 
    variety of functionality within [Losant's Workflow Engine](/workflows/overview/).

The Starter Workflow for this test suite is available [here](https://files.onlosant.com/5c40ecbac853d20008cd96ba/starter/starter-workflow-string.flow).`,
  exercises,
}
