import * as C from 'chance'
import { assert } from 'chai'
import * as R from 'ramda'
import Test from '../../Test'

const Chance = new C()

export const LABEL = 'add-to-element'

export default {
  label: LABEL,
  name: 'Add to Each Element',
  action: `
  When dealing with an array of data points, there will be cases in which you'll have to manipulate every single element
  in the array before doing something with the data. This could be converting a list of temperatures in Celsius to Fahrenheit.

  A JSON object containing an array of random numbers, "numbers", and a random number, "random", will be provided to your Webhook.
  Sum "random" with each value in "numbers" and return a JSON object containing a single field, "result", that is a summed array.
  `,
  examples: [
    {
      input: { random: 7, numbers: [1, 2, 3] },
      output: {
        result: [8, 9, 10],
      },
    },
    {
      input: { random: 11, numbers: [1, 2, 3] },
      output: {
        result: [12, 13, 14],
      },
    },
  ],
  recommended: `Resources: [Loop Node](/workflows/logic/loop/)`,
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function arrayIndex(webhook, test) {
      const array = []
      for (let i = 0; i < Math.floor(Math.random() * 20) + 5; i += 1) {
        array.push(Chance.integer({ min: 0, max: 100 }))
      }
      const randomNumber = Chance.integer({ min: 0, max: 100 })
      const random = x => x + randomNumber

      const expectedValue = R.map(random, array)
      const input = { random: randomNumber, numbers: array }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.deepEqual(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], arrayIndex)

    return this
  },
}
