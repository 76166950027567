import Test from '../../Test'

// Tests :)
import countWorkflowRuns from './countWorkflowRuns'
import countTwoWorkflowRuns from './countTwoWorkflowRuns'

const exercises = {}

exercises[countWorkflowRuns.label] = new Test(countWorkflowRuns)
exercises[countTwoWorkflowRuns.label] = new Test(countTwoWorkflowRuns)

export default {
  name: 'Workflow Storage',
  isRunning: false,
  webhook: null,
  description: `Each question in this test suite involves solving [Workflow Storage](/workflows/overview/#workflow-storage) questions. 
  
  The Starter Workflow for this test suite is available [here](https://files.onlosant.com/5c40ecbac853d20008cd96ba/starter/starter-workflow-workflow-storage.flow).`,
  exercises,
}
