import * as C from 'chance'
import moment from 'moment'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'format-date'

export default {
  label: LABEL,
  name: 'Formating Dates',
  action: `
  Now, let's format dates. When displaying dates in a [Losant Experience](/experiences/overview/),
  there are many ways you might want to format that date. Well, lucky for you, there is a special helper for that.
  
  A JSON object containing one date, "date", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is "date" in the format of 12/05/2020  (Month/Day/Year).
  `,
  examples: [
    {
      input: {
        date: '2099-05-26T11:36:47.206Z',
      },
      output: {
        result: '05/26/2099',
      },
    },
    {
      input: {
        date: '2019-09-06T13:30:52.557Z',
      },
      output: {
        result: '09/06/2019',
      },
    },
  ],
  recommended: `Resources: [Format Helpers](/workflows/accessing-payload-data/#format-helpers), [Format Strings](https://momentjs.com/docs/#/displaying/format/)`,

  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function format(webhook, test) {
      const date = Chance.date()

      const expectedValue = moment.utc(date).format('MM/DD/YYYY')

      const input = { date }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], format)

    return this
  },
}
