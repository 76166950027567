import Test from '../../Test'

// Tests :)
import formatTemplateRoundUp from './formatTemplateRoundUp'
import lengthTemplate from './lengthTemplate'
import trimTemplate from './trimTemplate'
import formatDate from './formatDate'
import getDateElement from './getDateElement'
import addTwoNumber from './addTwoNumber'

const exercises = {}

exercises[addTwoNumber.label] = new Test(addTwoNumber)
exercises[lengthTemplate.label] = new Test(lengthTemplate)
exercises[trimTemplate.label] = new Test(trimTemplate)
exercises[formatTemplateRoundUp.label] = new Test(formatTemplateRoundUp)
exercises[formatDate.label] = new Test(formatDate)
exercises[getDateElement.label] = new Test(getDateElement)

export default {
  name: 'Template Helpers',
  isRunning: false,
  webhook: null,
  description: `Templates are [Handlebars templates](http://handlebarsjs.com/) – they are 
  constructed using a payload path that is wrapped in double curly brackets, such as  \`{{foo.bar}}\`. 
  Handlebars helpers can also mutate a given value in place and print the result. Losant provides many [format helpers](/workflows/accessing-payload-data/#format-helpers).
  For example, the helper \`add\` (usage \`{{add val1 val2}}\`) will simply cast the two values as numbers and add them.
  Each question in this test suite involves using format helpers and the skills practiced in previous suites. 

  The Starter Workflow for this test suite is available [here](https://files.onlosant.com/5c40ecbac853d20008cd96ba/starter/starter-workflow-template-helpers.flow).`,
  exercises,
}
