import React, { useContext } from 'react'
import Gravatar from 'react-gravatar'
import styled from '@emotion/styled'
import UserContext from './UserContext'

const LosAvatar = () => {
  const [User, error] = useContext(UserContext)
  let image = <img src="/login-icon.svg" alt="Login Icon" />

  const WelcomeMessage = styled.div`
    font-family: 'Alternate Gothic', 'Franklin Gothic Medium', 'Arial Narrow';
    color: #ffffff;
    font-size: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    margin-right: 10px;
    @media (max-width: 785px) {
      display: none;
    }
  `
  let greeting = ''
  const welcome = 'Welcome back, '
  if (User && !error) {
    greeting = welcome + User.firstName

    image = (
      <Gravatar
        style={{ borderRadius: '40px', width: '32px' }}
        default="retro"
        size={40}
        email={User.email}
      />
    )
  }
  return (
    <>
      <WelcomeMessage>{greeting}</WelcomeMessage>
      <a
        target="_blank"
        rel="noopener noreferrer"
        title="Go to the Losant Platform"
        href="https://accounts.losant.com/signin?utm_campaign=Docs&utm_source=Docs"
      >
        {image}
      </a>
    </>
  )
}

export default LosAvatar
