import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';

const NoteWrapper = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;

  & > p {
    margin-bottom: 10px;
  }
  & > p:only-child {
    margin: 0;
  }
`
const NoteHeader = styled.h2`
  color: black !important;
  margin-top: 10px;
`

const Note = ({ title, text }) => {
  return (
    <NoteWrapper>
      {title && <NoteHeader>{title}</NoteHeader>}
      <ReactMarkdown source={text} />
    </NoteWrapper>
  )
}

Note.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

export default Note
