import React, { useContext, useEffect } from 'react'
import { Navbar } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Styles from '../styles/topbar.module.scss'
import LosantAvatar from './LosantAvatar'
import LayoutContext from './LayoutContext'

const TopBar = ({ location }) => {
  const { layoutState, layoutDispatch } = useContext(LayoutContext)
  const { isMobileNavOpen } = layoutState

  const mobileNavHandler = () => {
    layoutDispatch({ type: 'toggleMobileNav', state: !isMobileNavOpen })
  }

  useEffect(() => {
    layoutDispatch({ type: 'toggleMobileNav', state: false })
    // eslint-disable-next-line
  }, [location])

  return (
    <Navbar fixed="top" className={Styles.TopBar}>
      <div className={Styles.TopBarWrapper}>
        <div className={Styles.MobileMenu}>
          <FontAwesomeIcon onClick={mobileNavHandler} icon={faBars} />
        </div>
        <div className={Styles.Image}>
          <a href="/">
            <img
              src="https://www.losant.com/hubfs/Website/Images/losant_logo_horizontal_coolwhite.svg"
              alt="Losant | IoT Application Platform"
              title="Losant | IoT Application Platform"
            />
          </a>
        </div>
        <div className={Styles.LinkGroup}>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.losant.com/iot-platform?utm_campaign=Docs&utm_source=Docs"
              >
                I<span style={{ textTransform: 'lowercase' }}>o</span>T Platform
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://losant.com/blog?utm_campaign=Docs&utm_source=Docs"
              >
                Blog
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://forums.losant.com?utm_campaign=Docs&utm_source=Docs"
              >
                Forums
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.losant.com/contact-us?utm_campaign=Docs&utm_source=Docs"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>

        <div className={Styles.Button}>
          <LosantAvatar />
        </div>
      </div>
    </Navbar>
  )
}

export default TopBar
