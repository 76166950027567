import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from '@emotion/styled'

const NoteWrapper = styled.div`
  padding: 20px;
  background: #efefef;
  margin: 10px 0px 10px 0px;
  border-top: 5px #ff495c solid;

  & > p {
    margin-bottom: 10px;
  }

  & > p > img {
    margin: 10px 10px;
  }
`
const NoteHeader = styled.h2`
  color: black !important;
  margin-top: 10px;
`

const Note = ({ title, text }) => {
  return (
    <NoteWrapper>
      <NoteHeader>{title}</NoteHeader>
      <ReactMarkdown source={text} />
    </NoteWrapper>
  )
}

export default Note
