import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import * as R from 'ramda'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons'

const floatMiddle = css`
  align-self: center;
  justify-self: end;
  display: flex;
  margin-left: auto;
`
const PayloadList = styled.ol`
  color: #8a93a8;
  font-family: 'CP Mono', 'Andale Mono', 'Lucida Console', monospace;
  margin-top: 0px;
`

const preBackgroundColor = function(success, error) {
  if (success) {
    return '#E8FDEE'
  }

  if (error) {
    return 'rgb(251, 233, 233)'
  }

  return 'rgb(245, 246, 247)'
}

const centerContent = css`
  padding: 42px;
  justify-content: center;
  align-content: center;
`

const PayloadBorder = css`
  border-radius: 4px;
  border: 1px solid rgb(227, 229, 231);
`

const Payload = styled.pre`
  font-family: CP Mono, monospace;
  padding: 10px;
  margin: 0;
  font-size: 13px;
  line-height: 20px;
  flex-grow: 1;
  word-break: break-all;
  white-space: pre-wrap;
  color: #4a4a4a;
  ${props => (props.border ? PayloadBorder : '')}
  background-color: ${props =>
    props.pad
      ? 'rgb(245, 246, 247)'
      : preBackgroundColor(props.success, props.error)};
  ${props => (props.centered ? centerContent : '')}
`

const roomToGrow = css`
  padding: 10px;
`

const PayloadWrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-grow: 1;
  border-radius: 4px;
  flex-direction: column;
  margin-top: 10px;
  ${PayloadBorder}
  color: #4a4a4a;
  background-color: ${props => preBackgroundColor(props.success, props.error)};
  ${props => (props.pad ? roomToGrow : '')};
`

const MetaInfo = styled.span`
  padding: 5px 8px;
  font-size: 10px;
  text-align: center;
  color: #4a4a4a;
  align-content: center;
  line-height: 12px;
  ${PayloadBorder}
  background-color: rgb(245, 246, 247);

  :not(:first-of-type) {
    margin-left: 10px;
  }
`

const MetaWrapper = styled.span`
  display: flex;
  margin-top: 12px;
  flex-direction: row;
`

const GenericPayload = ({ data, success, error }) => {
  let font = null
  if (success) {
    font = (
      <FontAwesomeIcon icon={faCheckCircle} color="#8DB319" css={floatMiddle} />
    )
  } else if (error) {
    font = (
      <FontAwesomeIcon icon={faTimesCircle} color="#D92A2A" css={floatMiddle} />
    )
  }
  let result

  if (Array.isArray(data) && data.length > 1) {
    const entries = data.map((entry, index) => {
      let json = entry
      const isString = typeof entry === 'string' || entry instanceof String
      if (!isString) {
        json = JSON.stringify(entry, null, 2)
      }
      return (
        <li key={`${index}-entry`}>
          <PayloadWrapper>
            <Payload>
              {json}
              {font}
            </Payload>
          </PayloadWrapper>
        </li>
      )
    })

    result = <PayloadList>{entries}</PayloadList>
  } else {
    let json = Array.isArray(data) ? data[0] : data
    const isString = typeof data === 'string' || data instanceof String
    if (!isString) {
      json = JSON.stringify(json, null, 2)
    }
    result = (
      <PayloadWrapper success={success} error={error}>
        <Payload>
          {json}
          {font}
        </Payload>
      </PayloadWrapper>
    )
  }

  return <Fragment>{result}</Fragment>
}

const makePayloadPayload = entry => {
  const isString = typeof entry === 'string' || entry instanceof String
  if (!isString) {
    return JSON.stringify(R.omit(['httpStatusCode'], entry), null, 2)
  }
  return entry
}

const ActualOutput = ({ output, success, error, body, message }) => {
  let font = null
  if (success) {
    font = (
      <FontAwesomeIcon icon={faCheckCircle} color="#8DB319" css={floatMiddle} />
    )
  } else if (error) {
    font = (
      <FontAwesomeIcon icon={faTimesCircle} color="#D92A2A" css={floatMiddle} />
    )
  }

  const payload = makePayloadPayload(output)

  return (
    <PayloadWrapper pad success={success} error={error}>
      <Payload border>{payload}</Payload>
      <MetaWrapper>
        <MetaInfo>Status Code: {body.status}</MetaInfo>
        <MetaInfo>Time: {body.timing.time}ms</MetaInfo>
        {error && message ? (
          <MetaInfo>
            Error: {typeof message === 'string' ? message : message.toString()}
          </MetaInfo>
        ) : (
          <></>
        )}
        {font}
      </MetaWrapper>
    </PayloadWrapper>
  )
}

const ExampleInput = ({ input }) => {
  const payload = makePayloadPayload(input)

  return (
    <PayloadWrapper pad={false}>
      <Payload>{payload}</Payload>
    </PayloadWrapper>
  )
}

const ExampleOutput = ({ output, body }) => {
  let statusCode = null
  let meta = <></>
  if (body) {
    statusCode = body.status || null
  }
  const payload = makePayloadPayload(output)

  if (statusCode) {
    meta = (
      <MetaWrapper>
        <MetaInfo>Status Code: {statusCode}</MetaInfo>
      </MetaWrapper>
    )
  }

  return (
    <Fragment>
      <PayloadWrapper pad={statusCode}>
        <Payload>{payload}</Payload>
        {meta}
      </PayloadWrapper>
    </Fragment>
  )
}

const NoPayload = ({ text }) => {
  return (
    <PayloadWrapper pad={false}>
      <Payload centered>{text}</Payload>
    </PayloadWrapper>
  )
}

export default GenericPayload

export {
  GenericPayload as Payload,
  ExampleInput,
  ActualOutput,
  ExampleOutput,
  NoPayload,
}
