import Test from '../../Test'

// Tests :)
import addTwoNumbers from './addTwoNumbers'
import addThreeNumbers from './addThreeNumbers'
import roundOneNumber from './roundOneNumber'
import tempConvert from './tempConvert'
import checkForEven from './checkForEven'
import randomNumberBetweenTwo from './randomNumberBetweenTwo'

const exercises = {}

exercises[addTwoNumbers.label] = new Test(addTwoNumbers)
exercises[addThreeNumbers.label] = new Test(addThreeNumbers)
exercises[roundOneNumber.label] = new Test(roundOneNumber)
exercises[tempConvert.label] = new Test(tempConvert)
exercises[checkForEven.label] = new Test(checkForEven)
exercises[randomNumberBetweenTwo.label] = new Test(randomNumberBetweenTwo)

export default {
  name: 'Math',
  isRunning: false,
  webhook: null,
  description: `Welcome to your first suite. Each question in this test suite involves solving math questions using a 
    variety of functionality within [Losant's Workflow Engine](/workflows/overview/).  
    
The Starter Workflow for this test suite is available [here](https://files.onlosant.com/5c40ecbac853d20008cd96ba/starter/starter-workflow-math.flow).`,
  exercises,
}
