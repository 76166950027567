import Test from '../../Test'

// Tests :)
import requiredField from './requiredFields'

const exercises = {}

exercises[requiredField.label] = new Test(requiredField)

export default {
  name: 'Validation',
  isRunning: false,
  webhook: null,
  description: `
  Validating input is an important part of any IoT solution.
  If you're accepting user input from a Losant Experience, you must always ensure that you've received the details that
  you require and it's formatted correctly. Responding with appropriate codes and messages is also an important
  part of a well-designed system. This suite contains tests that will require your workflow to
  implement common validation techniques.

  The Starter Workflow for this test suite is available [here](https://files.onlosant.com/5c40ecbac853d20008cd96ba/starter/starter-workflow-validation.flow).`,
  exercises,
}
